//
// Dividers
// --------------------------------------------------


// Horizontal rule

hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $divider-color;
  &.hr-light { border-color: $divider-light-color; }

  &.hr-gradient {
    height: 1px;
    border: initial;
    background: $divider-gradient;
    &.hr-light { opacity: .4; }
  }
}


// Vertical divider
// (for flex container only)

.divider-vertical {
  display: block;
  position: relative;
  width: 0;
  &::before {
    display: block;
    width: $divider-vertical-width;
    height: 100%;
    background: $divider-vertical-gradient;
    content: '';
  }
  &.divider-light::before { opacity: $divider-vertical-light-opacity; }
}
