//
// Animations
// --------------------------------------------------


// General

.cs-from-top, .cs-from-bottom,
.cs-from-left, .cs-from-right,
.cs-scale-up, .cs-scale-down,
.cs-fade-in {
  transition: all .4s .3s ease-in-out;
  opacity: 0;
  backface-visibility: hidden;
}
.cs-from-top {
  transform: translateY(-2.25rem);
}
.cs-from-bottom {
  transform: translateY(2.25rem);
}
.cs-from-left {
  transform: translateX(-2.25rem);
}
.cs-from-right {
  transform: translateX(2.25rem);
}
.cs-scale-up {
  transform: scale(0.85);
}
.cs-scale-down {
  transform: scale(1.15);
}


// Utils

.cs-delay-1 { transition-delay: .5s; }
.cs-delay-2 { transition-delay: .7s; }
.cs-delay-3 { transition-delay: .9s; }
.cs-delay-4 { transition-delay: 1.1s; }


// Trigger

.tns-slide-active {
  .cs-from-top, .cs-from-bottom {
    transform: translateY(0);
    opacity: 1;
  }
  .cs-from-left, .cs-from-right {
    transform: translateX(0);
    opacity: 1;
  }
  .cs-scale-up, .cs-scale-down {
    transform: scale(1);
    opacity: 1;
  }
  .cs-fade-in { opacity: 1; }
}
