//
// Media
// --------------------------------------------------


.media > img {
  flex-shrink: 0;
}

.media-body {
  flex: inherit;
}
