//
// Buttons
// --------------------------------------------------


// Override button global settings

.btn {
  letter-spacing: $btn-letter-spacing;
  white-space: nowrap;
  > i { vertical-align: middle; }

  &.active { cursor: default; }
  &.disabled,
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
}
.btn-primary, .btn-success,
.btn-danger, .btn-warning,
.btn-info, .btn-dark,
.btn-outline-success:hover,
.btn-outline-success:active,
.btn-outline-info:hover,
.btn-outline-info:active,
.btn-outline-warning:hover,
.btn-outline-warning:active {
  color: $btn-dark-color !important;
}
.btn-secondary,
.btn-outline-secondary {
  color: $dark;
}
.btn-secondary {
  background-color: $gray-300;
  &:hover {
    background-color: $gray-400;
  }
  &.disabled,
  &:disabled {
    &:hover {
      background-color: $gray-300;
    }
  }
}
.btn-outline-secondary {
  border-color: $gray-400;
  &:hover {
    background-color: $gray-300;
  }
  &.disabled,
  &:disabled {
    &:hover {
      border-color: $gray-400;
    }
  }
}


// Translucent buttons

[class*=' btn-translucent'] {
  &:hover { color: $btn-dark-color !important; }
}
@each $color, $value in $theme-colors {
  .btn-translucent-#{$color} {
    background-color: rgba($value, $btn-translucent-bg-opacity);
    color: $value;
    &:hover {
      background-color: $value;
    }
  }
}


// Gradient button

.btn-gradient {
  color: $btn-dark-color !important;
  @include gradient-background;
}


// Light buttons :hover fixes

.btn-outline-light:hover,
.btn-translucent-light:hover {
  color: $btn-light-color !important;
}


// Shadow on hover effect

.btn-hover-shadow:hover {
  @each $color, $value in $theme-colors {
    &.btn-#{$color},
    &.btn-outline-#{$color},
    &.btn-translucent-#{$color} {
      box-shadow: 0 .5rem 1.125rem -.5rem rgba($value, .92);
    }
  }
  &.btn-gradient {
    box-shadow: 0 .5rem 1.125rem -.5rem rgba($gradient-from-color, .92);
  }
}


// Pill buttons

.btn-pill {
  border-radius: $btn-pill-border-radius !important;
  &.btn-sm {
    border-radius: $btn-pill-border-radius-sm !important;
  }
  &.btn-lg {
    border-radius: $btn-pill-border-radius-lg !important;
  }
}


// Btn link

.btn-link {
  font-weight: $btn-font-weight;
  color: $btn-link-color;
  &:hover {
    color: $btn-link-hover-color;
    text-decoration: none;
  }
  &:focus { text-decoration: none; }
}


// Icon sizes

.btn, .btn-sm {
  > i { @include font-size($btn-icon-size); }
}
.btn-lg {
  > i { @include font-size($btn-icon-size-lg); }
}


// Square buttons

.btn-square { border-radius: 0 !important; }


// Icon button

.btn-icon { padding: $btn-padding-y ($btn-padding-y * 1.3); }


// Market buttons

.btn-market {
  display: inline-block;
  padding: $btn-market-padding-y $btn-market-padding-x;
  padding-left: $btn-market-padding-x * 1.5 + $btn-market-icon-size;
  transition: $btn-market-outline-transition;
  @include border-radius($btn-market-border-radius);
  background: {
    position: center left .75rem;
    color: $btn-market-bg;
    size: $btn-market-icon-size $btn-market-icon-size;
    repeat: no-repeat;
  }
  text-decoration: none;
  white-space: nowrap;
  .btn-market-title {
    display: block;
    color: $btn-market-title-color;
    @include font-size($btn-market-title-font-size);
  }
  .btn-market-subtitle {
    display: block;
    margin-bottom: -.25rem;
    color: $btn-market-subtitle-color;
    font-size: $btn-market-subtitle-font-size;
  }
  &:hover {
    background-color: $btn-market-hover-bg;
    text-decoration: none;
  }
}

// App Store
.btn-apple {
  background-image: url('/assets/images/bootstrap/btn-apple');
}

// Google Play
.btn-google {
  background-image: url('/assets/images/bootstrap/btn-google');
}

// Windows Store
.btn-windows {
  background-image: url('/assets/images/bootstrap/btn-windows');
}

// Amazom Market
.btn-amazon {
  background-image: url('/assets/images/bootstrap/btn-amazon');
}


// Scroll to top button

.btn-scroll-top {
  display: block;
  position: fixed;
  text-decoration: none;
  right: $spacer * 1.25;
  bottom: $spacer * 1.25;
  width: $btn-scroll-top-size;
  height: $btn-scroll-top-size;
  transition: $btn-scroll-top-transition;
  transform: scale(0);
  @include border-radius(50%);
  background-color: $btn-scroll-top-bg;
  color: $btn-scroll-top-color;
  text-align: center;
  opacity: 0;
  z-index: $zindex-btn-scroll-top;
  > .btn-scroll-top-icon {
    @include font-size($btn-scroll-top-icon-font-size);
    font-weight: regular;
    line-height: $btn-scroll-top-size - .125rem;
  }

  .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: $btn-scroll-top-hover-bg;
    color: $btn-scroll-top-hover-color;
    .btn-scroll-top-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  &.right-15 {
    right: ($spacer * 1.25) + .9375rem;
  }

  @include media-breakpoint-down(sm) {
    width: $btn-scroll-top-size * .75;
    height: $btn-scroll-top-size * .75;
    right: $spacer;
    bottom: $spacer;
    > .btn-scroll-top-icon {
      line-height: $btn-scroll-top-size * .75;
    }
    &.right-15 {
      right: $spacer + .9375rem;
    }
  }
}
