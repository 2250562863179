// 
// user.scss
// Use this to write your custom SCSS
//

// Fonction pour ajouter les arrière plans des pages missions réalisées
@mixin backroundConstructor($ID) {
  
      .mission-background#{$ID} {
        background-image: url('../images/portfolio/#{$ID}/page-title-bg.jpg');
      }
}
@include backroundConstructor('01');
@include backroundConstructor('02');
@include backroundConstructor('03');
@include backroundConstructor('04');
@include backroundConstructor('05');
@include backroundConstructor('06');
@include backroundConstructor('07');
@include backroundConstructor('08');
@include backroundConstructor('09');
@include backroundConstructor('10');
@include backroundConstructor('11');
@include backroundConstructor('12');
@include backroundConstructor('13');
@include backroundConstructor('14');
@include backroundConstructor('15');

// Background Page Actions
  .bg {
    animation:slide 3s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, rgb(221, 232, 255) 50%, rgb(252, 250, 231) 50%);
    bottom:0;
    left:-50%;
    opacity:.5;
    position:fixed;
    right:-50%;
    top:0;
    z-index:-1;
  }
  
  .bg2 {
    animation-direction:alternate-reverse;
    animation-duration:4s;
  }
  
  .bg3 {
    animation-duration:5s;
  }
  
  @keyframes slide {
    0% {
      transform:translateX(-25%);
    }
    100% {
      transform:translateX(25%);
    }
  }

// Responsive nav
    @media screen and (min-width: 992px) {
        .w-100.w-lg-auto {
            width: auto !important;
        }
    }

// Promo
  .promo {
    z-index: 9998;
    top: 80px;
    right: 0px;
  }

// Head of Home - Litle Nav 
  a.whiteHover:hover {
    color: white !important;
  }


// Special rules
  .opacity-0 {
    opacity: 0;
  }
  .fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
  .fs-6 {
    font-size: 1rem !important;
  }
  .maxWidth48 {
    max-width: 48px;
  }

  .maxWidth260{
    max-width: 260px;
  }

  .maxWidth320 {
    max-width: 320px;
  }

  .maxWidth360 {
    max-width: 360px;
  }

  .maxWidth495 {
    max-width: 495px;
  }

  .maxWidth700 {
    max-width: 700px;
  }

  .translateY50 {
    transform: translateY(50%);
  }

  .topRight0 {
    top: 0; right: 0;
  }

  .index1 {
    z-index: 1;
  }

  .index2 {
    z-index: 2;
  }

  .index3 {
    z-index: 3;
  }

  .index4 {
    z-index: 4;
  }

  .index5 {
    z-index: 5;
  }

  .fontSize40 {
    font-size: 40px;
  }

  .marginTopBottom15 {
    margin-top:15px; margin-bottom:15px;
  }

  .specialBorder{
    border-width:5px !important;
    max-width: 600px;
  }

//Special btn
.btn-tag {
  display: inline-block;
  color: #1e212c;
  text-align: center;
  font-size: 1rem !important;
  text-decoration: none !important;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50rem !important;
  padding: 1px 6px;
  padding-right: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  transition: color .2s ease-in-out,background-color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
}
  .btn-tag:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

.btn-add {
    position: fixed;
    bottom: 16px;
    right: 20px;
    -webkit-animation: 1s ease 1s reverse both slidein;
    -o-animation: 1s ease 1s reverse both slidein;
    animation: 1s ease 1s reverse both slidein;
}
  @media screen and (min-width: 768px) {
    .btn-add {
      bottom: 32px;
      right: 40px;
    }
  }

@media (min-width: 300px) {
  .d-s-block {
      display: block !important;
  }
}
@media (min-width: 300px) {
  .col-s-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
}


.img-box img {
  display: block;
  height: 100%;
  max-height: 160px;
  max-width: none;
}
@media (min-width: 450px) {
  .img-box img {
    max-height: 195px;
  }
}

.translate-test {
 transform: translate(100%,-50%);// transform: translate(100%,-100%);
}
.top-0 {
  top:0px;
}
.right-0 {
  right:0px;
}
.left-0 {
  left:0px;
}
.share {
  top:0px;
  right:0px;
}
@media (min-width: 576px) {
  .share {
    right:15px;
  }
  .right-sm-15 {
    right:15px;
  }
}
@media (min-width: 768px) {
  .share {
    right:30px;
  }
  .right-md-30 {
    right:30px;
  }
}
//Backgrounds
  #aboutBg {
    background-image: url(/assets/images/about/page-title-bg.jpg);
  }

  #conseilsBg {
    background-image: url(/assets/images/prestations/conseils/page-title-bg.jpg);
  }

  #adminMissionBg {
    background-image: url(/assets/images/other/page-title-bg.jpg);
  }

  #contactBg {
    background-image: url(/assets/images/contacts/page-title-bg.jpg);
  }

  #adminBg {
    background-image: url(/assets/images/other/page-title-bg.jpg);
  }

  #adminBgCard1 {
    background-image: url(/assets/images/home/services/01.jpg);
  }

  #adminBgCard2 {
    background-image: url(/assets/images/home/services/02.jpg);
  }

  #adminBgCard3 {
    background-image: url(/assets/images/home/services/03.jpg);
  }

  #adminBgCard4 {
    background-image: url(/assets/images/home/services/04.jpg);
  }
  #formationBg {
    background-image: url(/assets/images/prestations/formations/page-title-bg.jpg);
  }

  #homeBg{
    background-image: url(/assets/images/home/hero-bg.jpg);
  }

  #homeBg2 {
    background-image: url(/assets/images/home/bg-stars.svg);
  }
    @media screen and (min-width: 768px) {
      #homeBg2 {
        background-image: none !important;
      }
    }

  #homeBgCard1 {
    background-image: url(/assets/images/home/services/01.jpg);
  }

  #homeBgCard2 {
    background-image: url(/assets/images/home/services/02.jpg);
  }

  #homeBgCard3 {
    background-image: url(/assets/images/home/services/03.jpg);
  }

  #homeBgCard4 {
    background-image: url(/assets/images/home/services/04.jpg);
  }

  #homeShapes {
    background-image: url(/assets/images/forms/bg-shape-alt.jpg);
  }

  #mlegalesBg {
    background-image: url(/assets/images/mentions-legales/page-title-bg.jpg);
  }

  #methodesBg {
    background-image: url(/assets/images/prestations/methodes/page-title-bg.jpg);
  }

  #pConfBg {
    background-image: url(/assets/images/mentions-legales/page-title-bg.jpg);
  }

  #missionBgLogo {
    background-color: rgba(255, 255, 255, 0.7);
  }

  #portfolioBg {
    background-image: url(/assets/images/portfolio/page-title-bg.jpg);
  }

  #formBg {
    background-image: url(/assets/images/forms/detail-form-bg.jpg);
  }

  #postMissionBg {
    background-image: url(/assets/images/other/page-title-bg.jpg);
  }

  #postMissionBg2 {
    background-image: url(/assets/images/forms/detail-form-bg.jpg);
  }

  #prestationsBg {
    background-image: url(/assets/images/prestations/page-title-bg.jpg);
  }

  #servicesBg {
    background-image: url(/assets/images/prestations/services/page-title-bg.jpg);
  }

  .bg-x {
    min-height: 100vh;
    background-image: url(/assets/images/other/bg-x.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  #endBg {
    background-color: #292C37;
  }

  .bg-purple {
    background-color: #be53f0;
  }
    a.bg-purple:hover, a.bg-purple:focus, button.bg-purple:hover, button.bg-purple:focus {
      background-color: #a048c9 !important;
    }
  .bg-brown {
    background-color: #926252;
  }
    a.bg-brown:hover, a.bg-brown:focus, button.bg-brown:hover, button.bg-brown:focus {
      background-color: #bb8e7f !important;
    }
  .bg-black {
    background-color: #3d3537;
  }
    a.bg-black:hover, a.bg-black:focus, button.bg-black:hover, button.bg-black:focus {
      background-color: #6d5f63 !important;
    }
  .bg-orange {
    background-color: #ffa268;
  }
    a.bg-orange:hover, a.bg-orange:focus, button.bg-orange:hover, button.bg-orange:focus {
      background-color: #ff8d46 !important;
    }
  .bg-pink {
    background-color: #ff6cac;
  }
    a.bg-pink:hover, a.bg-pink:focus, button.bg-pink:hover, button.bg-pink:focus {
      background-color: #f84693 !important;
    }
  .bg-yellow {
    background-color: #fff87c;
  }
    a.bg-yellow:hover, a.bg-yellow:focus, button.bg-yellow:hover, button.bg-yellow:focus {
      background-color: #fcef00 !important;
    }
  .bg-white {
    background-color: #ffffff;
  }
    a.bg-white:hover, a.bg-white:focus, button.bg-white:hover, button.bg-white:focus {
      background-color: #c4c4c4 !important;
    }

    .btn-google:hover {
      opacity: .5!important;
    }

    .btn-google {
      border-color: #fff!important;
      background: -webkit-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: -o-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: -moz-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: linear-gradient(45deg,#00dbde,#fc00ff)!important;
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      -moz-transition: all .4s;
      transition: all .4s;
    }
    .btn-gradient-1:hover {
      border-color: #fff!important;
      background: -webkit-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: -o-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: -moz-linear-gradient(45deg,#00dbde,#fc00ff)!important;
      background: linear-gradient(45deg,#00dbde,#fc00ff)!important;
      -webkit-transition: all .4s;
      -o-transition: all .4s;
      -moz-transition: all .4s;
      transition: all .4s;
    }

  .frameGoogle {
    height:0;
    width:0;
    display:none;
    visibility:hidden;
  }

  .cs-media-tabs .nav-link.active img.cs-media-tabs-icon {
    filter: brightness(0) invert(1);
}

.cursor-pointer {
  cursor: pointer;
}

.fw-medium {
  font-weight: 500;
}
.min-vh-100 {
  min-height: 100vh;
}
.mw-700 {
  max-width: 700px;
}
//Form Research
.input-search {
  border-radius: 50rem 0 0 50rem!important;
}
.btn-search {
  border-radius: 0 50rem 50rem 0!important;
}


.underline {
  text-decoration: underline;
}

.strikethrough {
  text-decoration: line-through;
}

input[type="file" i] {
  font-size: 0.875rem !important;
}


.max-vw-100 { max-width: 100vw !important; }
.max-vh-100 { max-height: 100vh !important; }
.max-vw-80 { max-width: 80vw !important; max-width: 100%!important; }
.max-vh-70 { max-height: 70vh !important; }


// Date picker
  .datepicker table tr td.active.active {
    background-color: #E87018;
  }
  
  .datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
    background-color: #E87018;
    background-image: -moz-linear-gradient(to bottom,#ffc107,#E87018);
    background-image: -ms-linear-gradient(to bottom,#ffc107,#E87018);
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#ffc107),to(#E87018));
    background-image: -webkit-linear-gradient(to bottom,#ffc107,#E87018);
    background-image: -o-linear-gradient(to bottom,#ffc107,#E87018);
    background-image: linear-gradient(to bottom,#ffc107,#E87018);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc107', endColorstr='#E87018', GradientType=0);
    border-color: #E87018 #E87018 #b34c04;
    border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
  }

  .datepicker-dropdown {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  .datepicker {
    padding: 0;
  }

  .datepicker td, .datepicker th {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.85rem;
    border-radius: 0px;
  }

  .datepicker-dropdown:after {
    border-bottom-color: #ffc107;//#08c;
  }

  .datepicker-days .table-condensed thead tr:nth-child(2), .datepicker-months .table-condensed thead tr:nth-child(2),
  .datepicker-years .table-condensed thead tr:nth-child(2), .datepicker-decades .table-condensed thead tr:nth-child(2),
  .datepicker-centuries .table-condensed thead tr:nth-child(2) {
    background: linear-gradient(to bottom,#ffc107,#E87018);
    color: #fff;
  }
  .datepicker-days .table-condensed thead tr:nth-child(2) th:hover, .datepicker-months .table-condensed thead tr:nth-child(2) th:hover,
  .datepicker-years .table-condensed thead tr:nth-child(2) th:hover,.datepicker-decades .table-condensed thead tr:nth-child(2) th:hover,
  .datepicker-centuries .table-condensed thead tr:nth-child(2) th:hover {
    background: linear-gradient(to bottom,#ffc107,#E87018);
    color: #fff;
  }

  .datepicker-days .table-condensed tfoot, .datepicker-months .table-condensed tfoot, .datepicker-years .table-condensed tfoot,
  .datepicker-decades .table-condensed tfoot, .datepicker-centuries .table-condensed tfoot {
    border-top: solid 1px rgba(0,0,0,.15);
  }


// Personalisation de la validation de formulaire
  #editeur {
    height: 60vh; // La hauteur de l'éditeur
    overflow: auto; // Cette propriété sert à envoyer le texte à la ligne au cas où il dépasse la largeur définie avant
  }
  .form-control-editor {
    display: block;
    width: 100%;
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #424551;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dadbdd;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color .2s ease-in-out,background-color .2s ease-in-out,box-shadow .2s ease-in-out;
  }
  .form-control-editor:focus-within {
      color: #424551;
      background-color: #fff;
      border-color: rgba(232,112,24,.4);
      outline: 0;
      box-shadow: 0 0 0 0 transparent, 0 5rem 5rem -1.25rem rgb(232 112 24 / 8%), 0 1.875rem 1.5rem -0.625rem rgb(232 112 24 / 5%), 0 0.75rem 0.625rem -0.375rem rgb(232 112 24 / 4%), 0 0.25rem 0.25rem -0.25rem rgb(30 33 44 / 3%);
    }

  #editeur:focus-visible {
    outline: -webkit-focus-ring-color auto 0px!important;
  }

  #articleMainImgPath {
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #424551;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dadbdd;
    border-radius: 0.25rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color .2s ease-in-out,background-color .2s ease-in-out,box-shadow .2s ease-in-out;
  }
  #articleMainImgPath:focus-within {
      color: #424551;
      background-color: #fff;
      border-color: rgba(232,112,24,.4);
      outline: 0;
      box-shadow: 0 0 0 0 transparent, 0 5rem 5rem -1.25rem rgb(232 112 24 / 8%), 0 1.875rem 1.5rem -0.625rem rgb(232 112 24 / 5%), 0 0.75rem 0.625rem -0.375rem rgb(232 112 24 / 4%), 0 0.25rem 0.25rem -0.25rem rgb(30 33 44 / 3%);
    }

  .was-validated .form-control-file:invalid, .form-control-file.is-invalid {
      border-color: #ff4242!important;
  }
    .was-validated .form-control-file:valid, .form-control-file.is-valid {
        // border-color: #03cea4;
        padding-right: calc(1.5em + 1.25rem);
        background-image: url(/assets/images/bootstrap/11.svg);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.3125rem) center;
        background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
    }
    .was-validated .form-control:invalid~.form-control-editor, .form-control.is-invalid~.form-control-editor {
      border-color: #ff4242!important;
    }
    .was-validated .form-control:valid~.form-control-editor, .form-control.is-valid~.form-control-editor {
        // border-color: #03cea4;
        padding-right: calc(1.5em + 1.25rem);
        background-image: url(/assets/images/bootstrap/11.svg);
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.3125rem) top calc(0.375em + 0.3125rem);
        background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
    }

    .was-validated .form-check-input:valid~.form-check-label, .form-check-input.is-valid~.form-check-label {
      color: #424551!important;
    }
    .shadow-xs{
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    }

// specific
.h-5p{
  height: 5px!important;
};
.pbar-1 {
  width: 10%;
};

// Article thumbnail
.truncate-l2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box!important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.truncate-l3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box!important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
  // Share buttons
  .share-group{
    background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0));
    top:0px;
    left:0px;
    height:100%;
    -webkit-animation: .5s reverse slideToRight;
    -o-animation: .5s reverse slideToRight;
    animation: .5s reverse slideToRight;
  }
  @keyframes slideToRight {
    from { transform: translate(0%); }
    to   { transform: translate(-100%); }
  }
  .share-group-control:hover + .share-group, .share-group:hover {
    display: flex!important;
  }

  .h-88px {
    height: 88px!important;
  }

.hover-red:hover{
  color: red;
}
.active-color:disabled{
  color: #424551!important;
}
// Article récents card
.focus-slide-title {
  width: 100%;
  height: calc(calc(calc(calc(1rem * 1.5) * 3) + calc(.25rem * 2)) + 0.75rem);
  background-color: rgba(255, 255, 255, .9);
}

.card:hover .focus-slide-title {
  width: 100%;
  min-height: calc(calc(calc(calc(1rem * 1.5) * 3) + calc(.25rem * 2)) + 0.75rem);
  max-height: calc(calc(calc(calc(1rem * 1.5) * 6) + calc(.25rem * 2)) + 0.75rem);
  height: auto;
  background-color: rgba(255, 255, 255, 0.9);
}
.card:hover .focus-slide-title .card-title{
  -webkit-line-clamp: 6;
}

// Social media colors
.share-btn a:hover {
  cursor: pointer;
}
.smc-fb {
  color: #275c9e!important;
}
.smc-in {
  color: #007cbb!important;
}
.smc-tw {
  color: #00aae3 !important;
}
.smc-ml {
  color: #fa4635 !important;
}
.smc-sh {
  color: #01c94e !important;
}
.smc-cp {
  color: #29425c !important;
}