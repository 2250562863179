//
// Font Icons
// Font generated using:
// https://icomoon.io/app
// --------------------------------------------------
$icomoon-font-family: "ensemble-icons" !default;
$icomoon-font-path: "../fonts" !default;

$cxi-share-square-o: "\f045";
$cxi-twitter-square: "\f081";
$cxi-facebook-square: "\f082";
$cxi-linkedin-square: "\f08c";
$cxi-envelope-square: "\f199";
$cxi-share-alt-square: "\f1e1";
$cxi-home: "\e900";
$cxi-newspaper: "\e906";
$cxi-pencil: "\e905";
$cxi-image: "\e90d";
$cxi-paste: "\e92d";
$cxi-phone: "\e944";
$cxi-location: "\e947";
$cxi-history: "\e94d";
$cxi-clock: "\e94e";
$cxi-calendar: "\e953";
$cxi-drawer: "\e95c";
$cxi-drawer2: "\e95d";
$cxi-undo: "\e965";
$cxi-redo: "\e966";
$cxi-user: "\e971";
$cxi-user-plus: "\e973";
$cxi-user-minus: "\e974";
$cxi-search: "\e987";
$cxi-cog: "\e994";
$cxi-magic-wand: "\e997";
$cxi-delete: "\e9ac";
$cxi-clipboard: "\e9b8";
$cxi-list-numbered: "\e9b9";
$cxi-list: "\e9bb";
$cxi-tree: "\e9bc";
$cxi-menu: "\e9bd";
$cxi-earth: "\e9ca";
$cxi-link: "\e9cb";
$cxi-eye: "\e9ce";
$cxi-eye-blocked: "\e9d1";
$cxi-warning: "\ea07";
$cxi-notification: "\ea08";
$cxi-plus1: "\ea0a";
$cxi-cancel-circle: "\ea0d";
$cxi-enter: "\ea13";
$cxi-exit: "\ea14";
$cxi-filter: "\ea5b";
$cxi-font-size: "\ea61";
$cxi-bold: "\ea62";
$cxi-underline: "\ea63";
$cxi-italic: "\ea64";
$cxi-strikethrough: "\ea65";
$cxi-paragraph-left: "\ea77";
$cxi-paragraph-center: "\ea78";
$cxi-paragraph-right: "\ea79";
$cxi-paragraph-justify: "\ea7a";
$cxi-share1: "\ea7d";
$cxi-share: "\ea82";
$cxi-mail: "\ea84";
$cxi-facebook: "\ea90";
$cxi-linkedin: "\eaca";
$cxi-file-pdf: "\eadf";
$cxi-arrow-down: "\e902";
$cxi-arrow-left: "\e903";
$cxi-arrow-right: "\e904";
$cxi-arrow-up: "\e907";
$cxi-arrow-convert: "\e909";
$cxi-cross: "\e913";
$cxi-minus: "\e914";
$cxi-plus: "\e915";
$cxi-angle-down: "\e916";
$cxi-angle-left: "\e917";
$cxi-angle-right: "\e918";
$cxi-angle-up: "\e919";
$cxi-heart: "\e91f";
$cxi-iphone: "\e937";
$cxi-upload: "\e941";
$cxi-whatsapp: "\e952";
$cxi-grid: "\e986";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9lrqmw');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9lrqmw#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9lrqmw') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9lrqmw') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9lrqmw##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="cxi-"], [class*=" cxi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cxi-share-square-o {
  &:before {
    content: $cxi-share-square-o; 
  }
}
.cxi-twitter-square {
  &:before {
    content: $cxi-twitter-square; 
  }
}
.cxi-facebook-square {
  &:before {
    content: $cxi-facebook-square; 
  }
}
.cxi-linkedin-square {
  &:before {
    content: $cxi-linkedin-square; 
  }
}
.cxi-envelope-square {
  &:before {
    content: $cxi-envelope-square; 
  }
}
.cxi-share-alt-square {
  &:before {
    content: $cxi-share-alt-square; 
  }
}
.cxi-home {
  &:before {
    content: $cxi-home; 
  }
}
.cxi-newspaper {
  &:before {
    content: $cxi-newspaper; 
  }
}
.cxi-pencil {
  &:before {
    content: $cxi-pencil; 
  }
}
.cxi-image {
  &:before {
    content: $cxi-image; 
  }
}
.cxi-paste {
  &:before {
    content: $cxi-paste; 
  }
}
.cxi-phone {
  &:before {
    content: $cxi-phone; 
  }
}
.cxi-location {
  &:before {
    content: $cxi-location; 
  }
}
.cxi-history {
  &:before {
    content: $cxi-history; 
  }
}
.cxi-clock {
  &:before {
    content: $cxi-clock; 
  }
}
.cxi-calendar {
  &:before {
    content: $cxi-calendar; 
  }
}
.cxi-drawer {
  &:before {
    content: $cxi-drawer; 
  }
}
.cxi-drawer2 {
  &:before {
    content: $cxi-drawer2; 
  }
}
.cxi-undo {
  &:before {
    content: $cxi-undo; 
  }
}
.cxi-redo {
  &:before {
    content: $cxi-redo; 
  }
}
.cxi-user {
  &:before {
    content: $cxi-user; 
  }
}
.cxi-user-plus {
  &:before {
    content: $cxi-user-plus; 
  }
}
.cxi-user-minus {
  &:before {
    content: $cxi-user-minus; 
  }
}
.cxi-search {
  &:before {
    content: $cxi-search; 
  }
}
.cxi-cog {
  &:before {
    content: $cxi-cog; 
  }
}
.cxi-magic-wand {
  &:before {
    content: $cxi-magic-wand; 
  }
}
.cxi-delete {
  &:before {
    content: $cxi-delete; 
  }
}
.cxi-clipboard {
  &:before {
    content: $cxi-clipboard; 
  }
}
.cxi-list-numbered {
  &:before {
    content: $cxi-list-numbered; 
  }
}
.cxi-list {
  &:before {
    content: $cxi-list; 
  }
}
.cxi-tree {
  &:before {
    content: $cxi-tree; 
  }
}
.cxi-menu {
  &:before {
    content: $cxi-menu; 
  }
}
.cxi-earth {
  &:before {
    content: $cxi-earth; 
  }
}
.cxi-link {
  &:before {
    content: $cxi-link; 
  }
}
.cxi-eye {
  &:before {
    content: $cxi-eye; 
  }
}
.cxi-eye-blocked {
  &:before {
    content: $cxi-eye-blocked; 
  }
}
.cxi-warning {
  &:before {
    content: $cxi-warning; 
  }
}
.cxi-notification {
  &:before {
    content: $cxi-notification; 
  }
}
.cxi-plus1 {
  &:before {
    content: $cxi-plus1; 
  }
}
.cxi-cancel-circle {
  &:before {
    content: $cxi-cancel-circle; 
  }
}
.cxi-enter {
  &:before {
    content: $cxi-enter; 
  }
}
.cxi-exit {
  &:before {
    content: $cxi-exit; 
  }
}
.cxi-filter {
  &:before {
    content: $cxi-filter; 
  }
}
.cxi-font-size {
  &:before {
    content: $cxi-font-size; 
  }
}
.cxi-bold {
  &:before {
    content: $cxi-bold; 
  }
}
.cxi-underline {
  &:before {
    content: $cxi-underline; 
  }
}
.cxi-italic {
  &:before {
    content: $cxi-italic; 
  }
}
.cxi-strikethrough {
  &:before {
    content: $cxi-strikethrough; 
  }
}
.cxi-paragraph-left {
  &:before {
    content: $cxi-paragraph-left; 
  }
}
.cxi-paragraph-center {
  &:before {
    content: $cxi-paragraph-center; 
  }
}
.cxi-paragraph-right {
  &:before {
    content: $cxi-paragraph-right; 
  }
}
.cxi-paragraph-justify {
  &:before {
    content: $cxi-paragraph-justify; 
  }
}
.cxi-share1 {
  &:before {
    content: $cxi-share1; 
  }
}
.cxi-share {
  &:before {
    content: $cxi-share; 
  }
}
.cxi-mail {
  &:before {
    content: $cxi-mail; 
  }
}
.cxi-facebook {
  &:before {
    content: $cxi-facebook; 
  }
}
.cxi-linkedin {
  &:before {
    content: $cxi-linkedin; 
  }
}
.cxi-file-pdf {
  &:before {
    content: $cxi-file-pdf; 
  }
}
.cxi-arrow-down {
  &:before {
    content: $cxi-arrow-down; 
  }
}
.cxi-arrow-left {
  &:before {
    content: $cxi-arrow-left; 
  }
}
.cxi-arrow-right {
  &:before {
    content: $cxi-arrow-right; 
  }
}
.cxi-arrow-up {
  &:before {
    content: $cxi-arrow-up; 
  }
}
.cxi-arrow-convert {
  &:before {
    content: $cxi-arrow-convert; 
  }
}
.cxi-cross {
  &:before {
    content: $cxi-cross; 
  }
}
.cxi-minus {
  &:before {
    content: $cxi-minus; 
  }
}
.cxi-plus {
  &:before {
    content: $cxi-plus; 
  }
}
.cxi-angle-down {
  &:before {
    content: $cxi-angle-down; 
  }
}
.cxi-angle-left {
  &:before {
    content: $cxi-angle-left; 
  }
}
.cxi-angle-right {
  &:before {
    content: $cxi-angle-right; 
  }
}
.cxi-angle-up {
  &:before {
    content: $cxi-angle-up; 
  }
}
.cxi-heart {
  &:before {
    content: $cxi-heart; 
  }
}
.cxi-iphone {
  &:before {
    content: $cxi-iphone; 
  }
}
.cxi-upload {
  &:before {
    content: $cxi-upload; 
  }
}
.cxi-whatsapp {
  &:before {
    content: $cxi-whatsapp; 
  }
}
.cxi-grid {
  &:before {
    content: $cxi-grid; 
  }
}