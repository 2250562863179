// Font Face
@mixin fontface($family, $name, $weight: normal, $style: normal) {
  $path: '../fonts/';
  @font-face {
    font-family: $family;
    src: url('#{$path}#{$name}.eot');
    src: url('#{$path}#{$name}.eot?#iefix') format('embedded-opentype'), url('#{$path}#{$name}.woff') format('woff'), url('#{$path}#{$name}.ttf') format('truetype'), url('#{$path}#{$name}.svg##{$name}') format('svg');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

//Ubuntu family 
@include fontface("Ubuntu", "Ubuntu-Light", 300, normal);
@include fontface("Ubuntu", "Ubuntu-LightItalic", 300, italic);
@include fontface("Ubuntu", "Ubuntu-Regular", 400, normal);
@include fontface("Ubuntu", "Ubuntu-Italic", 400, italic);
@include fontface("Ubuntu", "Ubuntu-Medium", 500, normal);
@include fontface("Ubuntu", "Ubuntu-MediumItalic", 500, italic);
@include fontface("Ubuntu", "Ubuntu-Bold", 700, normal);
@include fontface("Ubuntu", "Ubuntu-BoldItalic", 700, italic);

$font-family-sans-serif: "Ubuntu", sans-serif, Roboto, -apple-system, Arial;
$font-family-monospace:       SFMono-Regular, "Courier New", monospace;
//
// Demo specific overrides
$primary:                 #E87018;
$path-to-fonts:          '../fonts/';

// Gradient
$gradient-from-color:   $primary;
$gradient-to-color:    #E97010;

 // Custom Variables
 @import 'user-variables';

// Theme Helpers: Variables, Mixins and Placeholders
@import 'helpers';

// Bootstrap Core
@import '../../vendor/bootstrap/scss/bootstrap.scss';

// Theme Core
@import 'reboot';
@import 'components';
@import 'utilities';

// Custom Core
@import 'user';